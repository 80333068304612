import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const element: any = document.getElementById("battleface-rp");
if (element) {
  const root = createRoot(element);
  root.render(
    <BrowserRouter>
      <App redirection={JSON.parse(element.dataset?.redirection || "false")} />
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
