import { FC, useContext } from "react";
import appStyles from "../../App.module.css";
import { formatAmount, purchasePathDocs } from "../../config/app-constants";
import { ISingleTripQuoteContext, ISingleTripQuoteData } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";
import PackageDisplayName from "./PackageDisplayName";
import PaymentIcons from "./PaymentIcons";
import SvgIcon from "./SvgIcon";

interface QuoteSummaryProps {
  pay: boolean;
}

const QuoteSummary: FC<QuoteSummaryProps> = (props: QuoteSummaryProps) => {
  const { pay } = props;
  const { quoteData, setQuoteData, quoteResult, next, packages } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;
  const removePackage = (packageAlias: string) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    if (
      _quoteData.packages[packageAlias]?.defaultLevel?.id &&
      _quoteData.packages[packageAlias]?.level?.id !==
        _quoteData.packages[packageAlias]?.defaultLevel?.id
    ) {
      const pkg = packages.find((_pkg: any) => _pkg.alias === packageAlias);
      _quoteData.packages[packageAlias] = {
        package: pkg,
        level: pkg?.levels[0],
        defaultLevel: pkg?.levels[0],
      };
    } else {
      delete _quoteData.packages[packageAlias];
    }
    setQuoteData(_quoteData);
  };

  // showing all the packages which is selected
  const benefitsAdded: any = quoteResult?.details?.filter(
    (pkg: any) => pkg.type !== "level" && pkg.type !== "condition"
  );

  return (
    <div className={`${appStyles.quoteTotalBoxOuter}`}>
      <div className={`${appStyles.borderBox} ${appStyles.quoteTotalBox}`}>
        <div className={`${appStyles.textCenter} ${appStyles.quoteSummaryWrapper}`}>
          <h6 className={appStyles.colorPrimary}>Travel Insured For</h6>
          <h4 className={appStyles.colorPrimary}>
            {formatAmount(
              quoteResult?.quoted_price || 0,
              quoteData?.host_country?.code || "AU",
              quoteData.currency?.value || "AUD"
            )}
          </h4>
          <small className={`${appStyles.priceText} ${appStyles.colorPrimary}`}>
            (Incl. govt fees & charges)
          </small>
          <div className={appStyles.paymentButtonWrapper}>
            <div className={`${appStyles.buttonBlock} ${appStyles.buttonBlockAlignCenter}`}>
              <button
                className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfButtonSecondary} ${appStyles.bfbuttonIconAfter}`}
                type={pay ? "submit" : "button"}
                onClick={() => {
                  !pay && next();
                }}
              >
                {pay ? "Buy Now" : "Checkout"}
                {` ${formatAmount(
                  quoteResult?.quoted_price || 0,
                  quoteData?.host_country?.code || "AU",
                  quoteData.currency?.value || "AUD"
                )}`}
                <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
                  <SvgIcon icon='forward-arrow-icon' />
                </span>
              </button>
            </div>

            <PaymentIcons />
          </div>
        </div>

        {benefitsAdded?.length > 0 ? (
          <div id='addedBenefits' className={`${appStyles.quoteSelectedBenifitsWrapper}`}>
            <h6>Your selected benefits:</h6>
            {benefitsAdded.map((result: any, index: number) => {
              const pkgDetails: any = quoteData.packages[result.package_alias];

              const isRequired = pkgDetails?.package?.validations?.find((elem: any) => {
                return elem?.product_package_validation_type?.name === "Is Required";
              });

              return (
                <div className={appStyles.quoteSelectedBenifitBlock} key={index}>
                  <div className={appStyles.quoteSelectedBenifit}>
                    <PackageDisplayName packageName={result.type} />

                    <b>
                      <div>
                        {` ${formatAmount(
                          result.amount,
                          quoteData?.host_country?.code || "AU",
                          quoteData.currency?.value || "AUD"
                        )}`}
                      </div>
                    </b>
                  </div>
                  {!isRequired ? (
                    <div className={appStyles.btnDeleteWrap}>
                      {!pay ? (
                        <a
                          className={appStyles.btnDelete}
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            removePackage(result.package_alias);
                          }}
                        >
                          <SvgIcon icon='clear-border-circle-icon' />
                        </a>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
        <div className={appStyles.quoteSummaryPdfLink}>
          <small
            className={appStyles.summaryLink}
            onClick={() => window.open(purchasePathDocs.PDS, "blank")}
          >
            View PDS & FSG
          </small>
        </div>
      </div>
    </div>
  );
};

export default QuoteSummary;
