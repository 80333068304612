import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import appStyles from "../../App.module.css";
import appConstants, { getFormatedAddress } from "../../config/app-constants";
import { IOrderForm } from "../../config/interfaces";
import SvgIcon from "./SvgIcon";
type Props = {
  onPlaceSelect: (_addressData: any | null) => void;
  onPlaceClear: () => void;
  name: string;
  value: string;
  id: string;
  className: string;
  isDisabled?: boolean;
};

const options = {
  componentRestrictions: { country: "AU" },
};

const AddressSearchInput: React.FC<Props> = (props: Props) => {
  const { onPlaceSelect, onPlaceClear, name, value, id, className, isDisabled = false } = props;

  const { placesService, getPlacePredictions, placePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: appConstants.GOOGLE_API_KEY,
      debounce: 1500,
      libraries: ["places"],
      ...options,
    });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const suggestionsRef = useOnclickOutside(() => {
    setShowSuggestions(() => false);
  });

  const { handleBlur } = useFormikContext<IOrderForm>();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const [queryAddress, setQueryAddress] = useState(value);

  useEffect(() => {
    if (value) {
      setQueryAddress(() => value);
      setIsPlaceSelected(() => true);
    } else {
      setQueryAddress(() => "");
    }
  }, [value]);

  useEffect(() => {
    if (placePredictions?.length && !suggestions?.length) {
      setSuggestions(() => placePredictions);
    }
  }, [placePredictions]);

  const handleSearch = async () => {
    try {
      if (!queryAddress) return;

      setShowSuggestions(() => true);
      getPlacePredictions({ input: queryAddress, ...options });
    } catch (error) {
      console.error("Error fetching data from Google Places API", error);
    }
  };

  const handlePlaceSelect = (item: any) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails: any) => {
        if (placeDetails) {
          const _placeDetails = getFormatedAddress(placeDetails, item);

          onPlaceSelect(_placeDetails);
          setQueryAddress(() => "");
          setShowSuggestions(() => false);
          setIsPlaceSelected(() => true);
          setSuggestions(() => []);
        }
      }
    );
  };

  const handleClearSearchedPlace = () => {
    if (onPlaceClear) {
      setQueryAddress(() => "");
      setShowSuggestions(() => false);
      setIsPlaceSelected(() => false);
      setSuggestions(() => []);
      onPlaceClear();
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300);
    }
  };
  return (
    <div className={`${appStyles.placesDropdown}`}>
      <div className={`${appStyles.formField} ${appStyles.fieldHasIcon} `}>
        <input
          ref={inputRef}
          type='text'
          name={name}
          id={id}
          className={className}
          placeholder='Search Address'
          value={queryAddress}
          onBlur={handleBlur}
          disabled={isDisabled ? isPlaceSelected : false}
          onChange={(event: any) => {
            const inputValue = event.target.value;
            setQueryAddress(() => inputValue);
            if (!inputValue || inputValue.length < 3) {
              setShowSuggestions(() => false);
              return;
            } else {
              setSuggestions(() => []);
            }
            handleSearch();
          }}
          onFocus={() => {
            setShowSuggestions(() => true);
          }}
        />

        {!isPlacePredictionsLoading && queryAddress ? (
          <button className={appStyles.btnClose} onClick={() => handleClearSearchedPlace()}>
            <span className={`${appStyles.filedIcon}`}>
              <SvgIcon icon='clear-icon' />
            </span>
          </button>
        ) : null}
        {isPlacePredictionsLoading ? (
          <span className={`${appStyles.filedIconWrap}`}>
            <span className={`${appStyles.spinnerLoader}`}></span>
          </span>
        ) : null}
      </div>
      {showSuggestions && (
        <div className={`${appStyles.placesDropdownMenu}`} ref={suggestionsRef}>
          {suggestions.map((item, index) => (
            <button
              className={`${appStyles.dropdownItem}`}
              key={`item-${item?.place_id}-${index}`}
              onClick={() => handlePlaceSelect(item)}
              type='button'
            >
              <span className={`${appStyles.locationIcon}`}>
                <span className={`${appStyles.filedIcon}`}>
                  <SvgIcon icon='location-icon' />
                </span>
              </span>
              {item.description}
            </button>
          ))}
        </div>
      )}
      {queryAddress.length > 0 && queryAddress.length < 3 && !showSuggestions && (
        <div className={appStyles.placesDropdownMenu}>
          <span className={`${appStyles.dropdownItem} ${appStyles.warningText}`}>
            Please type 3 or more characters to start searching.
          </span>
        </div>
      )}
    </div>
  );
};
export default AddressSearchInput;
