import { FC, useContext, useState } from "react";
import appStyles from "../../App.module.css";
import { calculateAge, formatDate } from "../../config/app-constants";
import { ISingleTripQuoteContext, SelectOption } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";

interface TripSummaryProps {}

const TripSummary: FC<TripSummaryProps> = (props: TripSummaryProps) => {
  const [showTripSummary, setShowTripSummary] = useState<boolean>(false);

  const { quoteData } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  return (
    <div className={`${appStyles.tripSummary} ${appStyles.tripSummaryVisible}`}>
      <div
        className={`${appStyles.tripSummaryTitle}`}
        onClick={(e) => {
          setShowTripSummary(!showTripSummary);
        }}
      >
        <p className={appStyles.helperText}>Trip Summary</p>
      </div>
      <div className={`${appStyles.tripSummaryContent}`}>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Destination(s)</div>
          <div className={appStyles.tripSummaryValue}>
            {quoteData?.destinations?.map((dest: SelectOption) => dest?.name).join(", ")}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Travel duration</div>
          <div className={appStyles.tripSummaryValue}>
            {`${formatDate(quoteData.start_date || new Date())} to
            ${formatDate(quoteData.end_date || new Date())}`}
          </div>
        </div>

        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>No. of Insured</div>
          <div className={appStyles.tripSummaryValue}>
            {(quoteData?.other_applicants?.length || 0) + 1}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Age(s)</div>
          <div className={appStyles.tripSummaryValue}>
            {[quoteData.main_applicant]
              .concat(quoteData.other_applicants?.filter((dob: Date | null) => dob != null))
              .map((dob: Date | null) => {
                return calculateAge(dob).years;
              })
              .join(", ")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripSummary;
