import { useContext } from "react";
import appStyles from "../../App.module.css";
import { formatAmount, getBenefitsCount } from "../../config/app-constants";
import { ISingleTripQuoteContext } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";
import SvgIcon from "./SvgIcon";
type MobileFooterProps = {
  title: string;
  onClickBtn?: () => void;
};
const MobileFooter = ({ title, onClickBtn }: MobileFooterProps) => {
  const { quoteData, quoteResult } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  return (
    <div className={appStyles.bfMobileFooter}>
      <div className={appStyles.bfMobileFooterLeft}>
        <h4 className={appStyles.colorPrimary}>
          {formatAmount(
            quoteResult?.quoted_price || 0,
            quoteData?.host_country?.code || "AU",
            quoteData.currency?.value || "AUD"
          )}
        </h4>
        <a
          className={appStyles.bfbadge}
          href='#addedBenefits'
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("addedBenefits")?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }}
        >
          {getBenefitsCount(quoteData)} benefits added
        </a>
      </div>
      <div className={appStyles.bfMobileFooterRight}>
        <button
          className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
          type={title === "checkout" ? "button" : "submit"}
          onClick={onClickBtn}
        >
          {title}
          {` ${formatAmount(
            quoteResult?.quoted_price || 0,
            quoteData?.host_country?.code || "AU",
            quoteData.currency?.value || "AUD"
          )}`}
          <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
            <SvgIcon icon='forward-arrow-icon' />
          </span>
        </button>
      </div>
    </div>
  );
};

export default MobileFooter;
