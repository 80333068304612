import { FC } from "react";
import appStyles from "../../App.module.css";
import SvgIcon from "../common/SvgIcon";
interface PrevButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

const PrevButton: FC<PrevButtonProps> = (props: PrevButtonProps) => {
  return (
    <button
      className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfButtonOutline} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconBefore}`}
      type='button'
      onClick={props.onClick}
    >
      Back
      <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconPrev}`}>
        <SvgIcon icon='back-arrow-icon' />
      </span>
    </button>
  );
};

export default PrevButton;
