import { FC, useContext, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ISingleTripQuoteContext } from "../config/interfaces";
import { SingleTripQuoteContext } from "./TripQuote";
import OrderDetail from "./steps/OrderDetail";
import OrderForm from "./steps/OrderForm";
import PaymentForm from "./steps/PaymentForm";
import QuoteDetail from "./steps/QuoteDetail";
import QuoteForm from "./steps/QuoteForm";

interface StepProps {}

const Step: FC<StepProps> = () => {
  const { currentStep, quoteResult, redirection, order, quoteData, packages } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const checkQuoteDetailAccess =
    quoteData.packages !== null && quoteResult !== null && packages.length > 0;
  const checkOrderFormAccess = quoteResult !== null && quoteData.packages !== null;
  const checkPaymentIconsAccess = order !== null;

  return (
    <Routes>
      <Route path='/' element={<QuoteForm />} />
      <Route
        path='/quote-details'
        element={
          <GuardedRoute isAllowed={checkQuoteDetailAccess}>
            <QuoteDetail />
          </GuardedRoute>
        }
      />
      <Route
        path='/order-form'
        element={
          <GuardedRoute isAllowed={checkOrderFormAccess}>
            <OrderForm />
          </GuardedRoute>
        }
      />
      <Route
        path='/payment-form'
        element={
          <GuardedRoute isAllowed={checkPaymentIconsAccess}>
            <PaymentForm />
          </GuardedRoute>
        }
      />
      <Route
        path='/thank-you'
        element={
          <GuardedRoute isAllowed={checkPaymentIconsAccess}>
            <OrderDetail />
          </GuardedRoute>
        }
      />
    </Routes>
  );
};

export default Step;

const GuardedRoute = ({ isAllowed, children }: any) => {
  const { restart } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!isAllowed) {
      restart();
      navigate(`/?${location.search}`);
    }
  }, [isAllowed, restart]);

  return isAllowed ? <>{children}</> : null;
};
