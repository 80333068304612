import appStyles from "../../App.module.css";
import appConstants from "../../config/app-constants";
const ShowApiError = () => {
  return (
    <div className={appStyles.formRow}>
      <div className={appStyles.battlefaceServerError}>{appConstants.defaultAPIError}</div>
    </div>
  );
};

export default ShowApiError;
