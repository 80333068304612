import { useFormikContext } from "formik";
import { FC, useContext, useState } from "react";
import appStyles from "../../App.module.css";
import { excessFee, formatAmount } from "../../config/app-constants";
import { ISingleTripQuoteContext, ISingleTripQuoteData } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";
import SvgIcon from "../common/SvgIcon";
import { IQuoteDetailForm } from "../steps/QuoteDetail";
interface ExcessFeeProps {
  updateExcessFee: () => void;
}

const ExcessFee: FC<ExcessFeeProps> = ({ updateExcessFee }: ExcessFeeProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { quoteData, setQuoteData } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  const { values, setFieldValue } = useFormikContext<IQuoteDetailForm>();

  const onValueChange = (event: any) => {
    setFieldValue("excess_fee", parseInt(event.target.value));
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    _quoteData.excess_fee = parseInt(event.target.value);
    setQuoteData(_quoteData);
    updateExcessFee();
  };

  return (
    <div className={`${appStyles.collapseBlock}`}>
      <div className={appStyles.collapseHeader}>
        <div
          className={appStyles.collapseHeaderTitle}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <SvgIcon icon='question-mark-icon' />
          <p className={appStyles.colorPrimary}>Excess Amount</p>
        </div>

        <div className={appStyles.collapseHeaderPrice}>
          <h6 className={appStyles.p}>
            {excessFee?.map((_excessFee, index) => (
              <div key={index} className={`${appStyles.bfRadio} ${appStyles.bfRadioInline}`}>
                <label className={appStyles.colorPrimary}>
                  <input
                    type='radio'
                    value={_excessFee}
                    name={`excess_fee${index + 1}`}
                    onChange={onValueChange}
                    checked={values.excess_fee === _excessFee}
                  />
                  <span className={appStyles.bfRadiomark}></span>
                  {formatAmount(_excessFee, "AU", "AUD", 0, 0)}
                </label>
              </div>
            ))}
          </h6>
        </div>
      </div>

      {expanded && (
        <div className={appStyles.collapseBody}>
          <p className={appStyles.helperText}>
            The amount that you contribute in the event of a claim. Excess applies per person, per
            claim.
          </p>
        </div>
      )}
    </div>
  );
};

export default ExcessFee;
