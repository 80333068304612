import { FC } from "react";
import appStyles from "../../App.module.css";

type Props = {};

const BannerAdImage: FC<Props> = () => {
  return (
    <div className={`${appStyles.bannerContainer}`}>
      <a href='#' rel='noopener noreferrer' target='_blank'>
        <img src='./images/banner-v1.png' alt='banner-ad' />
      </a>
    </div>
  );
};

export default BannerAdImage;
