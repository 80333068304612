import appStyles from "../../App.module.css";
const LoadingIndicator = () => {
  return (
    <div className={appStyles.bfSpinnerOverlay}>
      <div className={appStyles.bfSpinner}></div>
    </div>
  );
};

export default LoadingIndicator;
