import { FC, useContext } from "react";
import appStyles from "../App.module.css";
import { ISingleTripQuoteContext } from "../config/interfaces";
import { SingleTripQuoteContext } from "./TripQuote";

interface TooltipIconProps {
  text: string;
  size?: "xs" | "sm";
  children?: JSX.Element;
}

const TooltipIcon: FC<TooltipIconProps> = (props: TooltipIconProps) => {
  const { redirection } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const sizeClasses = {
    xs: appStyles.toolTipXs,
    sm: appStyles.toolTipSm,
  };
  const sizeClass: string = props.size ? sizeClasses[props.size] : sizeClasses.xs;
  return (
    <div className={appStyles.bfpToolTip}>
      <span
        className={`${
          redirection ? appStyles.redirectionTooltipInfoIcon : appStyles.tooltipInfoIcon
        }`}
      />
      {props.text ? (
        <span
          className={`${appStyles.bfpToolTipText} ${sizeClass}`}
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></span>
      ) : (
        <span className={`${appStyles.bfpToolTipText} ${sizeClass}`}>{props.children}</span>
      )}
    </div>
  );
};

export default TooltipIcon;
