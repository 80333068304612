import { FC } from "react";

import appStyles from "../../App.module.css";
import { purchasePathDocs } from "../../config/app-constants";

interface FooterProps {}

const Footer: FC<FooterProps> = (props: FooterProps) => {
  return (
    <div className={appStyles.footerContentWrapper}>
      <p className={`${appStyles.helperText} ${appStyles.mB20}`}>
        By continuing, you agree to our&nbsp;
        <a
          className={`${appStyles.bfBlackLink}`}
          target='_blank'
          rel='noopener noreferrer'
          href={purchasePathDocs.privacyPolicy}
        >
          Privacy Policy
        </a>
        ,&nbsp;
        <a
          className={`${appStyles.bfBlackLink}`}
          target='_blank'
          rel='noopener noreferrer'
          href={purchasePathDocs.termsAndCondition}
        >
          Terms & Conditions
        </a>
        , and&nbsp;
        <a
          className={`${appStyles.bfBlackLink}`}
          target='_blank'
          rel='noopener noreferrer'
          href={purchasePathDocs.cookiePolicy}
        >
          Cookie Policy
        </a>
        .
      </p>
      <p className={appStyles.helperText}>
        Insurance is administered by battleface Insurance Services Pty Ltd (ABN 28 650 606 045, AFSL
        536280), also operating under the trading name Robin Assist, and is underwritten by Pacific
        International Insurance Pty Ltd (ABN 83 169 311 193). Travel Insured International (ARN
        001311294) is battleface’s authorised representative and acts on behalf of battleface. Any
        advice provided is general advice only. Prior to purchasing, please consider your financial
        situation and needs and read the Product Disclosure Statement (PDS) & Financial Services
        Guide (FSG) and Target Market Determination (TMD).
      </p>
    </div>
  );
};

export default Footer;
