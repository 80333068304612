import { FC, useContext } from "react";
import appStyles from "../../App.module.css";
import { formatAmount } from "../../config/app-constants";
import { ISingleTripQuoteContext } from "../../config/interfaces";
import SvgIcon from "../common/SvgIcon";
import { SingleTripQuoteContext } from "../TripQuote";
interface NextButtonProps {
  title: string;
  onClick?: () => void;
  type?: string;
  disable?: boolean;
}

const NextButton: FC<NextButtonProps> = (props: NextButtonProps) => {
  const { quoteData, quoteResult } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  const { title, onClick, type = "button", disable } = props;
  return (
    <button
      className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfButtonSecondary} ${appStyles.bfbuttonIconAfter}`}
      type={type === "submit" ? "submit" : "button"}
      onClick={onClick}
      disabled={disable}
    >
      {title}&nbsp;
      {`${formatAmount(
        quoteResult?.quoted_price || 0,
        quoteData?.host_country?.code || "AU",
        quoteData.currency?.value || "AUD"
      )}`}
      <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
        <SvgIcon icon='forward-arrow-icon' />
      </span>
    </button>
  );
};

export default NextButton;
