import { useContext, useState } from "react";

import appStyles from "../../App.module.css";
import { formatAmount, formatDate, purchasePathDocs } from "../../config/app-constants";
import { ISingleTripQuoteContext, SelectOption } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";
import PackageDisplayName from "./PackageDisplayName";

function OrderSummary() {
  const { quoteData, order, quoteResult } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;

  const [showTripSummary, setShowTripSummary] = useState<boolean>(true);
  const subTotal: number = order?.premium || 0;

  // showing all the packages which is selected and showing sum of default selected packages as base cover
  const benefitsAdded: any = quoteResult?.details?.filter(
    (pkg: any) => pkg.type !== "level" && pkg.type !== "condition"
  );

  return (
    <div
      className={`${appStyles.tripSummary} ${showTripSummary ? appStyles.tripSummaryVisible : ""}`}
    >
      <div
        className={`${appStyles.tripSummaryTitle} ${appStyles.tripSummaryAlwaysOpen}`}
        onClick={(e) => {
          setShowTripSummary(showTripSummary);
        }}
      >
        <p className={appStyles.helperText}>Order Summary</p>
      </div>
      <div className={`${appStyles.tripSummaryContent}`}>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Destination(s)</div>
          <div className={appStyles.tripSummaryValue}>
            {quoteData?.destinations?.map((dest: SelectOption) => dest?.name).join(", ")}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Number of travellers</div>
          <div className={appStyles.tripSummaryValue}>{order?.items?.length || 0}</div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Plan duration</div>
          <div className={appStyles.tripSummaryValue}>
            {formatDate(quoteData.start_date)} to {formatDate(quoteData.end_date)}
          </div>
        </div>

        <div className={`${appStyles.tripSummaryOrderBreakdown}`}>
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.justifyContentStart}>
              <div className={appStyles.tripSummaryLabel}>Total</div>
              <b>
                <small className={`${appStyles.colorPrimary}`}>&nbsp;(inc. tax)</small>
              </b>
            </div>
            <div className={`${appStyles.tripSummaryValue}  ${appStyles.colorSuccess}`}>
              {formatAmount(
                subTotal,
                quoteData?.host_country?.code || "AU",
                quoteData?.currency?.value || "AUD"
              )}
            </div>
          </div>

          {benefitsAdded.map((result: any, index: number) => {
            return (
              <div key={index} className={`${appStyles.tripSummaryRow}`}>
                <div className={`${appStyles.tripSummaryLabel} ${appStyles.pL15}`}>
                  <PackageDisplayName packageName={result.type} />
                </div>
                <div className={appStyles.tripSummaryValue}>
                  {formatAmount(
                    result.amount,
                    quoteData?.host_country?.code || "AU",
                    quoteData?.currency?.value || "AUD"
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`${appStyles.quoteSummaryPdfLink} ${appStyles.mB10} ${appStyles.mY10} ${appStyles.justifyContentStart} ${appStyles.pX15}`}
      >
        <small
          className={appStyles.summaryLink}
          onClick={() => window.open(purchasePathDocs.PDS, "_blank")}
        >
          View PDS & FSG
        </small>
      </div>
    </div>
  );
}

export default OrderSummary;
