import { FC } from "react";
import { packageDisplayNames } from "../../config/app-constants";

interface PackageDisplayNameProps {
  packageName: string;
}

const PackageDisplayName: FC<PackageDisplayNameProps> = (props: PackageDisplayNameProps) => {
  const displayName: string = packageDisplayNames[props.packageName] || props.packageName;
  return <>{displayName}</>;
};

export default PackageDisplayName;
