import appStyles from "../App.module.css";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiUrl: process.env.REACT_APP_BASE_URL,
  productId: process.env.REACT_APP_REACT_APP_PRODUCT_ID,
  datePickerFormat: "dd/MM/y",
  excessFee1: 0,
  excessFee2: 100,
  excessFee3: 250,
  publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY,
  EMAIL_REGEX: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  affiliatedId: process.env.REACT_APP_AFFILIATED_ID,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  defaultAPIError:
    "We are unable to process your order online. Please contact our Customer Services team on +61 2 8357 0839 or at anz@battleface.com.",
};

export const purchasePathDocs: { [key: string]: string } = {
  PDS: "/docs/PDS.pdf",
  TMD: "/docs/TMD.pdf",
  travelAlerts: "https://www.smartraveller.gov.au",
  privacyPolicy: "https://www.battleface.com/en-au/privacy-policy",
  termsAndCondition: "https://www.battleface.com/en-au/terms-conditions",
  cookiePolicy: "https://www.battleface.com/en-au/cookie-policy",
};

export const excessFee = [0, 100, 250];

export const pathToStep: { [key: string]: number } = {
  "quote-details": 1,
  "order-form": 2,
  "payment-form": 3,
  "thank-you": 4,
};

export const stepToPath = ["/", "/quote-details", "/order-form", "/payment-form", "/thank-you"];

export const packageDisplayNames: any = {
  "Trip Interruption Package": "Trip Cancellation and Interruption Package",
};

export const defaultPackageLevels: any = {
  "trip_interruption_package": {
    type: "$",
    value: "3000",
  },
  "travel_delay_package": {
    type: "$",
    value: "2000",
  },
  "personal_accident_package": {
    type: "$",
    value: "50000",
  },

  "personal_liability_package": {
    type: "$",
    value: "2500000",
  },
  "baggage_package": {
    type: "$",
    value: "1000",
  },
};

export const selectControlStyling = (state: any) => {
  const classes = [appStyles.bfFormControl];
  if (state.isFocused) {
    classes.push(appStyles.bfFormControlFocused);
  }
  return classes.join(" ");
};

export const selectOptionControlStyling = (state: any) => {
  const classes = [appStyles.bfFormControl];
  if (state.isSelected) {
    classes.push(appStyles.bfFormControlOptionSelected);
  }
  if (state.isFocused) {
    classes.push(appStyles.bfFormControlOptionFocused);
  }
  return classes.join(" ");
};

export const convertDateToApiFormat = (_date: Date | null) => {
  const date = new Date(_date!);
  return date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
        date.getDate()
      ).padStart(2, "0")}`
    : "";
};

//21/02/1999
export const shortDateFormat = (_date: Date | null) => {
  const date = new Date(_date!);
  return date
    ? `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getFullYear())}`
    : "";
};

// calculate Leap year
export const totalDaysInCurrentYear = (startDate: Date | null): number => {
  const _currentYear: any = startDate && new Date(startDate?.toString()).getFullYear();
  const _currentMonth: any = startDate && new Date(startDate.toString()).getMonth();
  return (startDate && _currentYear % 4 === 0 && _currentYear % 100 !== 0 && _currentMonth <= 1) ||
    (_currentYear % 400 === 0 && _currentMonth <= 1)
    ? 365
    : 364;
};

export const calculateAge = (_birthDate: Date | null, from: Date | null = null) => {
  const birthDate = new Date(_birthDate!);

  if (!birthDate) {
    return {
      years: 0,
      months: 0,
      days: 0,
    };
  }

  if (!from) {
    from = new Date();
  }

  // Calculate years
  let years;
  if (
    from.getMonth() > birthDate.getMonth() ||
    (from.getMonth() === birthDate.getMonth() && from.getDate() >= birthDate.getDate())
  ) {
    years = from.getFullYear() - birthDate.getFullYear();
  } else {
    years = from.getFullYear() - birthDate.getFullYear() - 1;
  }

  // Calculate months
  let months = 0;
  if (from.getDate() >= birthDate.getDate()) {
    months = from.getMonth() - birthDate.getMonth();
  } else if (from.getDate() < birthDate.getDate()) {
    months = from.getMonth() - birthDate.getMonth() - 1;
  }
  // make month positive
  months = months < 0 ? months + 12 : months;

  // Calculate days
  let days;
  // days of months in a year
  let monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (from.getDate() >= birthDate.getDate()) {
    days = from.getDate() - birthDate.getDate();
  } else {
    days = from.getDate() - birthDate.getDate() + monthDays[birthDate.getMonth()];
  }

  return {
    years,
    months,
    days,
  };
};
// check that travellers age is above 66
export const validateAge = (_mainApplicant: any, _otherApplicant: any, _startDate: any) => {
  const d = calculateAge(_mainApplicant, _startDate);

  if (d.years < 150 && d.years >= 66) {
    // Here check for 150 is to ensure that the year of birth entered is abobe 1900
    return "We've got You covered for trips lasting up to 31 days. If You require cover for longer adventures, please call Us on +44 (20) 4586 0607.";
  }

  for (let index = 0; index < _otherApplicant.length; index++) {
    const _otherApplicantDOB = _otherApplicant[index];

    const d2 = calculateAge(_otherApplicantDOB, _startDate);

    if (d2.years < 150 && d2.years >= 66) {
      // Here check for 150 is to ensure that the year of birth entered is abobe 1900
      return "We've got You covered for trips lasting up to 31 days. If You require cover for longer adventures, please call Us on +44 (20) 4586 0607.";
    }
  }
  return null;
};

// check that date is valid or not
export const isValidDate = (date: any) => {
  return convertDateToApiFormat(date).length === 10;
};

export const formatDate = (_date: Date | null, formatType?: string) => {
  const date = new Date(_date!);

  return date
    ? date?.toLocaleDateString("en-US", {
        year: "numeric",
        month: formatType === "short" ? "short" : "long",
        day: "numeric",
      })
    : "";
};

// DD/MM/yyyy
export const formatTripDates = (date: Date | null) =>
  date ? date.toLocaleDateString("en-GB") : "";

export const formatAmount = (
  amount: number,
  country: string,
  currency: string,
  minFractionDigit: number = 2,
  maxFractionDigits: number = 2
) =>
  new Intl.NumberFormat(`en-${country}`, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: minFractionDigit,
    maximumFractionDigits: maxFractionDigits,
  }).format(amount);

export const addDays = (dateInput: Date, count: number): Date => {
  let _date: Date = new Date(dateInput);
  let increasedDate = new Date(_date.getTime() + count * 86400000);
  increasedDate.setHours(0, 0, 0, 0);
  return increasedDate;
};

export const subtractYears = (dateInput: Date, count: number): Date => {
  let _date = new Date(dateInput);
  _date.setFullYear(_date.getFullYear() - count);

  return _date;
};

export const subtractMonths = (dateInput: Date, count: number): Date => {
  let _date = new Date(dateInput);
  _date.setMonth(_date.getMonth() - count);
  return _date;
};

export const duration = (
  start: Date | null,
  end: Date | null,
  type: "d" | "m" | "y" = "d"
): number => {
  if (!start || !end) {
    return 0;
  }
  const difference = end.getTime() - start.getTime();
  let divisor = 1000 * 3600 * 24; // fpr days
  switch (type) {
    case "y":
      divisor = 1000 * 3600 * 24 * 365.25;
      return difference / divisor;
  }
  return Math.ceil(difference / divisor) + 1;
};

export const getApplicantErrorClass = (errors: any, touched: any, index: any, name: string) => {
  if (
    errors?.applicants &&
    errors?.applicants[index] &&
    errors?.applicants[index][name] &&
    touched?.applicants &&
    touched?.applicants[index] &&
    touched?.applicants[index][name]
  ) {
    return appStyles.hasError;
  }
  return "";
};

export const getBenefitsCount = (quoteData: any) => {
  const hasExcessFees = quoteData?.excess_fee ? 1 : 0;
  const count = hasExcessFees + Object.keys(quoteData?.packages).length;
  return count;
};

export const handleRedirection = (values: any) => {
  const { start_date, end_date, destinations, main_applicant, other_applicants } = values;
  const purchasePathUrl = process.env.REACT_APP_TII_PURCHASE_PATH_URL || "";
  const url = new URL(purchasePathUrl);
  const params = new URLSearchParams(url.search);

  params.set("startDate", String(start_date));
  params.set("endDate", String(end_date));

  if (destinations && destinations.length > 0) {
    params.set("destinations", JSON.stringify(destinations));
  }

  if (main_applicant) {
    params.set("mainApplicant", String(main_applicant));
  }
  if (other_applicants) {
    params.set("otherApplicants", JSON.stringify(other_applicants));
  }

  url.search = params.toString();

  window.open(url, "_blank");
};

export const customPackageDescriptions: any = {
  travel_medical_package:
    "Unlimited cover (up to 12 months) for the treatment of a medical emergency, including COVID-19, during your trip. This includes cover for transportation costs to the nearest adequate medical facility or home in the event of a medical emergency during the trip.",
  trip_interruption_package:
    "Coverage for your pre-paid, non-refundable trip costs if you need to cancel or interrupt your trip for a covered reason. For instance, if you become sick with COVID-19 before your departure date, this provides a per person benefit up to the maximum of your selected level of cover.",
  travel_delay_package:
    "Up to $200 per day and $2,000 per trip towards the cost of accommodation when you are unexpectedly delayed for more than 6 hours.",
  baggage_package:
    "Up to $2,000 per item, to an overall maximum selected of $10,000 for baggage that is damaged, stolen or accidentally lost during your trip. This pack also includes up to $300 for essential purchases when your luggage is delayed or misplaced for more than 12 hours.",
  personal_accident_package:
    "Coverage up to $50,000 if you suffer accidental bodily injury during the trip which is direct cause of your death or permanent total disablement.",
  personal_liability_package:
    "Coverage up to $2,500,000 if your negligence during the trip causes property damage or personal injury.",
  rental_vehicle_damage_package:
    "Coverage up to $8,000 for the excess that you are liable for in the event of theft or damage to your rental vehicle.",
  snow_package:
    "Coverage for a range of events that may occur whilst you are participating in snow sport activities (limits & conditions apply).",
  motorcycle_moped_pack:
    " Cover for emergency medical expenses if you are injured whilst riding a motorcycle or moped on your trip (limits & conditions apply).",
  cruise_pack:
    " Cover for events that occur whilst you are on a multi-night cruise (limits & conditions apply).",
  pet_cover:
    "Up to $200 per day, to a maximum of $1400 if your return from overseas is delayed and you need to extend your pet boarding arrangements.",
};

export const updatePackageAmount = async (pkg: any, updatedPkg: any) => {
  if (!pkg?.rates || !updatedPkg?.rates) return { pkg };

  const _updatePackages: any = {
    rates: [],
    discounts: updatedPkg?.discounts || {},
  };

  pkg.rates.forEach((oldPkg: any) => {
    const newPkg = updatedPkg.rates.find((pkg: any) => pkg.package_id === oldPkg.package_id);

    if (newPkg) {
      const levelMismatch = oldPkg.levels[0]?.id !== newPkg.levels[0]?.id;

      if (newPkg.name === "Rental Vehicle Damage Package" || levelMismatch) {
        _updatePackages.rates.push(newPkg);
      } else {
        _updatePackages.rates.push(oldPkg);
      }
    } else {
      _updatePackages.rates.push(oldPkg);
    }
  });

  return _updatePackages;
};

export const getFormatedAddress = (details: any, selectedAddress?: any) => {
  const addressData: any = {
    place_id: details.place_id,
    full_address: selectedAddress?.description || details?.formatted_address || "",
    name: "",
    city: "",
    country: "",
    pin_code: "",
    state: "",
  };

  const address = details.address_components || [];

  address.forEach(({ long_name, short_name, types }: any) => {
    if (types.includes("postal_code")) {
      addressData.pin_code = long_name || addressData.pin_code;
    }

    if (types.includes("country")) {
      addressData.country = long_name || addressData.country;
    }
    if (types.includes("locality") && long_name.length) {
      addressData.city = long_name || address.city;
    }
    if (
      (types.includes("administrative_area_level_3") ||
        types.includes("administrative_area_level_2")) &&
      addressData.city === ""
    ) {
      addressData.city = long_name || addressData.city;
    }

    if (types.includes("administrative_area_level_1")) {
      addressData.state = short_name || addressData.state;
    }
  });

  addressData.name = addressData.city;

  return addressData;
};
