import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import { updatePackageAmount } from "../../config/app-constants";
import { ISingleTripQuoteContext, ISingleTripQuoteData } from "../../config/interfaces";
import NextButton from "../buttons/NextButton";
import PrevButton from "../buttons/PrevButton";
import Footer from "../common/Footer";
import LoadingIndicator from "../common/LoadingIndicator";
import MobileFooter from "../common/MobileFooter";
import PaymentIcons from "../common/PaymentIcons";
import QuoteSummary from "../common/QuoteSummary";
import ShowApiError from "../common/ShowApiError";
import TripSummary from "../common/TripSummary";
import ExcessFee from "../form-elements/ExcessFee";
import PackageSelector from "../form-elements/PackageSelector";
import Stepper from "../Stepper";
import { SingleTripQuoteContext } from "../TripQuote";

interface QuoteDetailProps {}

export interface IQuoteDetailForm {
  excess_fee: number;
  packages: any;
}

const validationSchema = yup.object().shape({
  excess_fee: yup.number().required(),
  packages: yup.object().required(),
});

const QuoteDetail: FC<QuoteDetailProps> = () => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [hasError, setHasError] = useState<string | null>(null);
  const [isExcessFeeModified, setIsExcessFeeModified] = useState(false);
  const [isQuoteUpdatedByLocal, setIsQuoteDataUpdatedByLocal] = useState<boolean>(false);

  const {
    quoteData,
    prev,
    next,
    setQuoteResult,
    packages,
    loader,
    getQuote,
    setLoader,
    getDiscountAmounts,
    packageAmount,
    setPackageAmount,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  useEffect(() => {
    if (firstLoad || isQuoteUpdatedByLocal) {
      setFirstLoad(false);
      setIsQuoteDataUpdatedByLocal(false);
      return;
    }
    updateQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  const updateQuote = async () => {
    try {
      setHasError(null);
      if (!Object.keys(quoteData.packages || {}).length) {
        return;
      }
      setLoader("quote");
      let _quoteData: ISingleTripQuoteData = { ...quoteData };

      if (isExcessFeeModified) {
        const _discount = await getDiscountAmounts(_quoteData, packages, true);
        setPackageAmount(_discount);
        setIsExcessFeeModified(false);
      } else {
        const _discount = await getDiscountAmounts(_quoteData, packages, false);
        const result = await updatePackageAmount(packageAmount, _discount);
        setPackageAmount(result);
      }

      const _quoteResult = await getQuote(_quoteData);
      setQuoteResult(_quoteResult);
    } catch (e: any) {
      console.error(e);
      setHasError(e.message);
    } finally {
      setLoader(null);
    }
  };

  const handleFormSubmit = (values: IQuoteDetailForm) => {};

  return (
    <>
      {loader === "quote" && <LoadingIndicator />}

      <>
        <div className={appStyles.btnWrapper}>
          <PrevButton onClick={() => prev()} />
        </div>
        <Formik
          initialValues={{
            excess_fee: quoteData.excess_fee,
            packages: quoteData.packages,
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={`${appStyles.mainContainer}`}>
                <div className={`${appStyles.quoteResultWrapper}`}>
                  <div className={`${appStyles.quoteDetailsBox}`}>
                    <Stepper />
                    <TripSummary />
                    <div className={appStyles.pageSubTitle}>
                      <h5 className={appStyles.colorPrimary}>Personalise Your Quote</h5>
                      <p>
                        You can personalise the quote by adding/removing packages as per your needs:
                      </p>
                    </div>
                    <div className={appStyles.collapseWrapper}>
                      <ExcessFee updateExcessFee={() => setIsExcessFeeModified(true)} />

                      {packages?.map((pkg: any, index: number) => {
                        return <PackageSelector key={index} pkg={pkg} />;
                      })}
                    </div>
                    <div className={appStyles.formRow}>
                      <div className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev}`}>
                        <div className={appStyles.buttonBlockPrev}>
                          <PrevButton onClick={() => prev()} />
                        </div>
                        <div className={`${appStyles.buttonBlockNext}`}>
                          <NextButton title='Checkout' onClick={() => next()} />
                          <PaymentIcons />
                        </div>
                      </div>
                    </div>

                    {hasError && <ShowApiError />}
                    <Footer />

                    <MobileFooter title={"checkout"} onClickBtn={() => next()} />
                  </div>
                  <QuoteSummary pay={false} />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </>
    </>
  );
};

export default QuoteDetail;
