export default class TiiStorageService {
  disableStorage;
  constructor(disableStorage: boolean) {
    this.disableStorage = disableStorage;
  }

  private setData(key: string, value: string): void {
    if (this.disableStorage) {
      return;
    }
    sessionStorage.setItem(key, value);
  }

  setPackages = (packages: any): void => {
    this.setData("_bf_tii_packages", JSON.stringify(packages));
  };

  getPackages = (): any | null => {
    const _packages: any = sessionStorage.getItem("_bf_tii_packages");
    return _packages ? JSON.parse(_packages) : null;
  };

  setQuoteData = (quoteData: any): void => {
    this.setData("_bf_tii_quote_data", JSON.stringify(quoteData));
  };

  getQuoteData = (): any | null => {
    const _quoteData: any = sessionStorage.getItem("_bf_tii_quote_data");
    return _quoteData ? JSON.parse(_quoteData) : null;
  };

  setQuoteResult = (quoteResult: any): void => {
    this.setData("_bf_tii_quote_result", JSON.stringify(quoteResult));
  };

  getQuoteResult = (): any | null => {
    const _quoteResult: any = sessionStorage.getItem("_bf_tii_quote_result");
    return _quoteResult ? JSON.parse(_quoteResult) : null;
  };

  setPackageAmount = (pkgAmount: any): void => {
    this.setData("_bf_tii_pkg_amount", JSON.stringify(pkgAmount));
  };

  getPackageAmount = (): any | null => {
    const _packageAmount: any = sessionStorage.getItem("_bf_tii_pkg_amount");
    return _packageAmount ? JSON.parse(_packageAmount) : null;
  };

  setAllHostCountires = (hostCountries: any): void => {
    this.setData("_bf_tii_all_host_countries", JSON.stringify(hostCountries));
  };

  getAllHostCountries = (): any | null => {
    const _hostCountries: any = sessionStorage.getItem("_bf_tii_all_host_countries");
    return _hostCountries ? JSON.parse(_hostCountries) : null;
  };

  setAllDestinationCountries = (destination: any): void => {
    this.setData("_bf_tii_all_destination_countires", JSON.stringify(destination));
  };

  getAllDestinationCountries = (): any | null => {
    const _destinations: any = sessionStorage.getItem("_bf_tii_all_destination_countires");
    return _destinations ? JSON.parse(_destinations) : null;
  };

  setCurrentStep = (currentStep: number): void => {
    this.setData("_bf_tii_current_step", JSON.stringify(currentStep));
  };

  getCurrentStep = (): any | null => {
    const _currentStep: any = sessionStorage.getItem("_bf_tii_current_step");
    return _currentStep ? JSON.parse(_currentStep) : null;
  };

  setOrder = (order: any): void => {
    this.setData("_bf_tii_order", JSON.stringify(order));
  };

  getOrder = (): any | null => {
    const _order: any = sessionStorage.getItem("_bf_tii_order");
    return _order ? JSON.parse(_order) : null;
  };

  clearStorageData = (): void => {
    return sessionStorage.clear();
  };
}
