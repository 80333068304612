import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import appStyles from "../../App.module.css";
import { shortDateFormat } from "../../config/app-constants";
import { ISingleTripQuoteContext } from "../../config/interfaces";
import { SingleTripQuoteContext } from "../TripQuote";
import BannerAdImage from "../common/BannerAdImage";
import Footer from "../common/Footer";
import OrderSummary from "../common/OrderSummary";
import SvgIcon from "../common/SvgIcon";

interface OrderDetailProps {}

const OrderDetail: FC<OrderDetailProps> = (props: OrderDetailProps) => {
  const { order, restart } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const navigate = useNavigate();
  return (
    <>
      <div className={`${appStyles.mainContainer} ${appStyles.W600} ${appStyles.spaceTop}`}>
        <div className={`${appStyles.quotePaymentWrapper}`}>
          <div className={`${appStyles.pageTitle} ${appStyles.mB30}`}>
            <h4 className={`${appStyles.colorSuccess}`}>Congratulations! You’re covered</h4>
          </div>
          <div className={`${appStyles.pageTitle} ${appStyles.mB42}`}>
            <p className={appStyles.helperText}>
              Check your inbox for an email with your policy documents and related information.
            </p>
          </div>
          <div
            className={`${appStyles.tripSummaryRow} ${appStyles.tripSummaryRowOrderInfo} ${appStyles.p0}`}
          >
            <p className={appStyles.helperText}>Order No: {order?.order_number}</p>
            <p className={appStyles.helperText}>
              Order Date: {shortDateFormat(new Date(order?.ordered_at))}
            </p>
          </div>

          <OrderSummary />

          <div className={`${appStyles.pageSubTitle} ${appStyles.bfMarginTop}`}>
            <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>Your policy</h5>
          </div>
          {order?.items.map((item: any, index: number) => (
            <div
              key={`${item.policy_number + index}`}
              className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormPolicyInformation}`}
            >
              <div className={`${appStyles.tripSummaryRow}`}>
                <div className={`${appStyles.tripPolicyInfo}`}>
                  <p className={`${appStyles.pBold} ${appStyles.m0}`}>
                    {`${item?.insured?.first_name || ""} ${item?.insured?.middle_name || ""} ${
                      item?.insured?.last_name
                    }` || ""}
                  </p>
                  <p className={`${appStyles.tripSummaryLabel} ${appStyles.m0}`}>Policy #</p>
                  <p className={`${appStyles.pBold} ${appStyles.m0}`}>{item?.policy_number}</p>
                </div>
              </div>
            </div>
          ))}

          <Footer />
          <BannerAdImage />
          <div className={appStyles.formRow}>
            <div
              className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev} ${appStyles.buttonBlockAlignCenter}`}
            >
              <button
                className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter} ${appStyles.bfButtonOutline} ${appStyles.mB30}`}
                type='button'
                onClick={(e) => {
                  restart();
                  navigate("/");
                }}
              >
                New Quote
                <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
                  <SvgIcon icon='forward-arrow-icon' />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
