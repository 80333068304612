import { ErrorMessage } from "formik";
import appStyles from "../../App.module.css";

type ErrorMessageProps = {
  name: string;
  msg?: string;
};
const ShowFormikError = ({ name, msg }: ErrorMessageProps) => {
  return (
    <div>
      <ErrorMessage name={name}>
        {() => <div className={appStyles.formError}>{msg ? msg : "This field is required"}</div>}
      </ErrorMessage>
    </div>
  );
};

export default ShowFormikError;
