import { FC } from "react";

import appStyles from "../../App.module.css";

type Props = {};

const Header: FC<Props> = (props: Props) => {
  return (
    <div className={appStyles.headerWrapper}>
      <div className={appStyles.brandLogoWrapper}>
        <img
          src='./images/travel-insured-logo.png'
          className={appStyles.logoWrap}
          alt='brand-logo'
        />
      </div>
      <div className={appStyles.supportWrapper}>
        <p className={appStyles.colorPrimary}>Contact Support at&nbsp;</p>
        <a
          className={`${appStyles.h6} ${appStyles.headerContactNumberStyle}`}
          href='tel:+61 2 8357 0839'
        >
          +61 2 8357 0839
        </a>
      </div>
    </div>
  );
};

export default Header;
