import React from "react";
import appStyles from "../../App.module.css";
interface PaymentIconsProps {}

const PaymentIcons: React.FC<PaymentIconsProps> = (props: PaymentIconsProps) => {
  return (
    <div className={`${appStyles.paymentIconsBlock}`}>
      <svg
        width='33'
        height='10'
        viewBox='0 0 33 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.8929 3.19484C16.8778 4.58453 18.2011 5.36533 19.1992 5.82378C20.2276 6.29656 20.5755 6.60459 20.5679 7.02722C20.5603 7.67908 19.7512 7.95845 18.9875 7.97278C17.6642 7.99427 16.8929 7.6361 16.2804 7.3639L15.804 9.48424C16.4165 9.74928 17.5583 9.98567 18.738 10C21.5131 10 23.3204 8.70344 23.3355 6.69055C23.3431 4.1404 19.6076 3.99714 19.6378 2.85817C19.6454 2.51433 19.9932 2.14183 20.7569 2.04871C21.135 1.99857 22.1785 1.96275 23.3582 2.47851L23.8194 0.429799C23.1843 0.207736 22.3676 0 21.3468 0C18.7455 0 16.908 1.31805 16.8929 3.19484ZM28.2809 0.179083C27.7742 0.179083 27.3508 0.458453 27.1542 0.888253L23.1918 9.84241H25.9594L26.5114 8.40258H29.8991L30.2167 9.84241H32.6667L30.5343 0.179083H28.2809ZM28.6665 2.7937L29.4681 6.4255H27.2752L28.6665 2.7937ZM13.5355 0.179083L11.3502 9.84241H13.9892L16.1745 0.179083H13.5355ZM9.63364 0.179083L6.88117 6.76218L5.7696 1.16762C5.64105 0.544413 5.12685 0.179083 4.55216 0.179083H0.0604941L0 0.458453C0.922531 0.6447 1.96605 0.952723 2.60123 1.28224C2.98688 1.48281 3.10031 1.65473 3.22886 2.13467L5.33102 9.84957H8.1213L12.4012 0.179083H9.63364Z'
          fill='#1A1F71'
        />
      </svg>
      <svg
        width='17'
        height='11'
        viewBox='0 0 17 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.87402 9.91252H10.2294V2.05017H5.87402V9.91252Z'
          fill='#EB611C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.15076 5.98315C6.15076 4.38811 6.89366 2.96786 8.05171 2.0538C7.20321 1.38374 6.1362 0.983154 4.97451 0.983154C2.2287 0.983154 0 3.21913 0 5.98315C0 8.74717 2.2287 10.9832 4.97815 10.9832C6.13984 10.9832 7.20685 10.5826 8.05535 9.91251C6.89366 8.99845 6.15076 7.5782 6.15076 5.98315Z'
          fill='#E41221'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.9505 9.07857V8.88556H15.8995L15.8413 9.0203L15.783 8.88556H15.732V9.07857H15.7684V8.9329L15.8231 9.06036H15.8595L15.9141 8.9329V9.07857H15.9505ZM15.6301 9.07857V8.91833H15.6956V8.88556H15.5317V8.91833H15.5973V9.07857H15.6301ZM16.1071 5.98315C16.1071 8.74353 13.8784 10.9832 11.129 10.9832C9.96727 10.9832 8.90026 10.5826 8.05176 9.91251C9.20981 8.99845 9.9527 7.5782 9.9527 5.98315C9.9527 4.38811 9.20981 2.96786 8.05176 2.0538C8.89662 1.38374 9.96727 0.983154 11.129 0.983154C13.8784 0.983154 16.1071 3.21913 16.1071 5.98315Z'
          fill='#F59E1E'
        />
      </svg>
      <svg width='37' height='9' viewBox='0 0 37 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.86991 2.79545L7.78921 4.75681H5.95062L6.86991 2.79545ZM26.0319 2.87936H22.486V3.74991H25.9722V5.05049H22.486V6.01543H26.0319V6.79158L28.5033 4.44215L26.0319 1.98783V2.87936ZM9.26964 0.42504H14.0333L15.0958 2.44933L16.0748 0.414551H28.4316L29.721 1.66269L31.0462 0.414551H36.7172L32.5267 4.45264L36.6814 8.45926H30.9149L29.6255 7.21113L28.2883 8.45926H8.13544L7.55043 7.21113H6.20133L5.61633 8.45926H0.960144L4.87611 0.414551H8.95923L9.26964 0.42504ZM19.5968 1.5578H16.9225L15.1317 5.26026L13.1975 1.5578H10.5471V6.5923L8.08768 1.5578H5.71184L2.87037 7.3265H4.72091L5.30591 6.07836H8.42197L9.00698 7.3265H12.2544V3.2045L14.3437 7.3265H15.7644L17.8418 3.21499V7.3265H19.5849L19.5968 1.5578ZM30.7239 4.45264L33.7444 1.5578H31.5716L29.6613 3.36183L27.8108 1.5578H20.7907V7.33699H27.7153L29.6374 5.51198L31.488 7.33699H33.7206L30.7239 4.45264Z'
          fill='#286CB4'
        />
      </svg>
    </div>
  );
};

export default PaymentIcons;
