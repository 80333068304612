import { FC } from "react";
import appStyles from "../../App.module.css";
import SvgIcon from "./SvgIcon";

interface PopupProps {
  title: string;
  onClose: () => void;
  children: JSX.Element;
  footer?: any;
}

const Popup: FC<PopupProps> = (props: PopupProps) => {
  const { title, onClose, children, footer } = props;

  return (
    <div className={appStyles.battlefacePluginModal}>
      <div className={appStyles.battlefacePluginModalOverlay}></div>
      <div className={appStyles.battlefacePluginModalContent}>
        <p className={appStyles.battlefacePluginModalClose} onClick={onClose}>
          <SvgIcon icon='clear-border-circle-icon' />
        </p>

        <div className={appStyles.battlefacePluginModalBody}>
          <div className={appStyles.battlefacePluginModalHeading}>
            <h5 className={`${appStyles.textCenter} ${appStyles.colorPrimary} ${appStyles.mB20}`}>
              {title}
            </h5>
          </div>

          <div className={appStyles.battlefacePluginModalContentData}>{children}</div>
          <div onClick={onClose}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
