import appStyles from "../../App.module.css";

const PreExistingMedicalCondition = () => {
  return (
    <div>
      <div className={`${appStyles.formBlock} ${appStyles.pX5}`}>
        <h6 className={`${appStyles.colorPrimary} ${appStyles.mT10}`}>
          Pre-existing medical conditions
        </h6>
        <p>
          Please consider your medical history carefully. This policy provides cover for unexpected
          sudden illnesses or serious injuries.
        </p>
        <p>
          We automatically include cover for specific pre-existing medical conditions (listed
          below), subject to the following:
        </p>
        <ul>
          <li className={appStyles.p}>
            you've not been hospitalised for the condition in the past 24 months, and;
          </li>
          <li className={appStyles.p}>
            your medications for the condition have remained unchanged for the past 6 months.
          </li>
        </ul>
        <p>
          Timeframes are in relation to the date of issue of the policy. If you have a pre-existing
          medical condition not included in this list, then it's not covered. We won't pay for any
          claims where that medical condition is a contributing factor. This definition applies to
          you, your travelling party, a close relative or any other person named on the Certificate
          of Insurance.
        </p>

        <h6 className={`${appStyles.colorPrimary} ${appStyles.mT30}`}>
          What's a pre-existing medical condition?
        </h6>
        <p>
          Our definition of pre-existing medical condition is, in the 12 months before buying the
          policy:
        </p>

        <ul>
          <li className={appStyles.p}>
            a medical or dental condition of which you're aware, or the related complication you
            have, or the symptoms of which you're aware;
          </li>
          <li className={appStyles.p}>
            a medical or dental condition currently being, or has been, investigated or treated by a
            medical practitioner (including dentist or chiropractor);
          </li>
          <li className={appStyles.p}>
            any condition for which you take prescribed medicine. Doesn't apply to common colds, flu
            or contraceptive medication;
          </li>
          <li className={appStyles.p}>any condition for which you've had surgery; or</li>
          <li className={appStyles.p}>
            any condition for which you see a medical specialist. This definition applies to you,
            your travelling party, a close relative or any other person named on the Certificate of
            Insurance.
          </li>
        </ul>

        <h6 className={`${appStyles.colorPrimary} ${appStyles.mT30}`}>
          Automatically covered pre-existing medical conditions
        </h6>
        <p>The following medical conditions are automatically covered under this policy.</p>
        <ul>
          <li className={appStyles.p}>Acne</li>
          <li className={appStyles.p}>
            Allergies – limited to rhinitis, chronic sinusitis, eczema, food intolerance, hay fever
          </li>
          <li className={appStyles.p}>
            Anxiety – if:
            <ul>
              <li className={appStyles.p}>
                Your prescribed medication hasn't changed within the last 12 months, or;
              </li>
              <li className={appStyles.p}>
                You have not been diagnosed with depression within the last 3 years, or;
              </li>
              <li className={appStyles.p}>
                You do not have any appointments pending with a psychologist or psychiatrist, or;
              </li>
              <li className={appStyles.p}>
                You have not needed to cancel or interrupt any previous travel plans due to your
                anxiety
              </li>
            </ul>
          </li>
          <li className={appStyles.p}>
            Asthma – if you:
            <ul>
              <li className={appStyles.p}>have no other lung disease, and;</li>
              <li className={appStyles.p}>
                are under 60 years of age on the date of policy purchase
              </li>
            </ul>
          </li>
          <li className={appStyles.p}>Attention Deficit Hyperactivity Disorder (ADHD)</li>
          <li className={appStyles.p}>Bell's Palsy</li>
          <li className={appStyles.p}>Benign paroxysmal positional vertigo (BPPV)</li>
          <li className={appStyles.p}>Bunions</li>
          <li className={appStyles.p}>Carpal tunnel syndrome</li>
          <li className={appStyles.p}>Cataracts</li>
          <li className={appStyles.p}>Coeliac disease</li>
          <li className={appStyles.p}>Congenital blindness</li>
          <li className={appStyles.p}>Congenital deafness</li>
          <li className={appStyles.p}>
            Depression – if:
            <ul>
              <li className={appStyles.p}>
                Your prescribed medication hasn't changed within the last 12 months, or;
              </li>
              <li className={appStyles.p}>
                You have not been hospitalised for your depression within the last 2 years, or;
              </li>
              <li className={appStyles.p}>
                You do not have any appointments pending with a psychologist or psychiatrist, or;
              </li>
              <li className={appStyles.p}>
                You have not needed to cancel or interrupt any previous travel plans due to your
                depression
              </li>
            </ul>
          </li>
          <li className={appStyles.p}>
            *Diabetes mellitus (also known as Type I diabetes) – if you:
            <ul>
              <li className={appStyles.p}>were diagnosed over 12 months ago, and;</li>

              <li className={appStyles.p}>
                have no eye, kidney, nerve or vascular complications, and;
              </li>

              <li className={appStyles.p}>
                don't also suffer from a known cardiovascular disease, hypertension, hyperlipidaemia
                or hypercholesterolaemia, and;
              </li>

              <li className={appStyles.p}>
                are under 60 years of age at the date of policy purchase.
              </li>
            </ul>
          </li>
          <li className={appStyles.p}>
            *Diabetes mellitus (also known as Type II diabetes) – if you:
            <ul>
              <li className={appStyles.p}>were diagnosed over 12 months ago, and;</li>

              <li className={appStyles.p}>
                have no eye, kidney, nerve or vascular complications, and;
              </li>

              <li className={appStyles.p}>
                don't also suffer from a known cardiovascular disease, hypertension, hyperlipidaemia
                or hypercholesterolaemia.
              </li>
            </ul>
          </li>
          <li className={appStyles.p}>Dry eye syndrome</li>
          <li className={appStyles.p}>
            Epilepsy – if there's been no change to your medication regime in the past 12 months
          </li>
          <li className={appStyles.p}>Folate deficiency</li>
          <li className={appStyles.p}>Gastric reflux</li>
          <li className={appStyles.p}>Goitre</li>
          <li className={appStyles.p}>Glaucoma</li>
          <li className={appStyles.p}>Graves' disease</li>
          <li className={appStyles.p}>Hiatus hernia</li>
          <li className={appStyles.p}>
            *Hypercholesterolaemia (high cholesterol) – if you don't also suffer from a known
            cardiovascular disease and/or diabetes
          </li>
          <li className={appStyles.p}>
            *Hyperlipidaemia (High Blood Lipids) – if you don't also suffer from a known
            cardiovascular disease and/or diabetes
          </li>
          <li className={appStyles.p}>
            *Hypertension (High Blood Pressure) – if you don't also suffer from a known
            cardiovascular disease and/or diabetes
          </li>
          <li className={appStyles.p}>Hypothyroidism – including Hashimoto's disease</li>
          <li className={appStyles.p}>Impaired glucose tolerance</li>
          <li className={appStyles.p}>Incontinence</li>
          <li className={appStyles.p}>Insulin resistance</li>
          <li className={appStyles.p}>Iron deficiency anaemia</li>
          <li className={appStyles.p}>Macular degeneration</li>
          <li className={appStyles.p}>Meniere's disease</li>
          <li className={appStyles.p}>Migraine</li>
          <li className={appStyles.p}>Nocturnal cramps</li>
          <li className={appStyles.p}>Osteopenia</li>
          <li className={appStyles.p}>Osteoporosis</li>
          <li className={appStyles.p}>Pernicious anaemia</li>
          <li className={appStyles.p}>Plantar fasciitis</li>
          <li className={appStyles.p}>Raynaud's disease</li>
          <li className={appStyles.p}>Sleep Apnoea</li>
          <li className={appStyles.p}>Solar keratosis</li>
          <li className={appStyles.p}>Trigeminal neuralgia</li>
          <li className={appStyles.p}>Trigger finger</li>
          <li className={appStyles.p}>Vitamin B12 deficiency</li>
        </ul>
        <p>
          * Diabetes (Type I and Type II), hypertension, hypercholesterolaemia and hyperlipidaemia
          are risk factors for cardiovascular disease. If you have a history of cardiovascular
          disease, and it's a pre-existing medical condition, cover for these conditions is also
          excluded.
        </p>
        <p>
          Claims for any pre-existing medical condition not listed above as an automatically covered
          pre-existing condition are excluded under this policy.
        </p>
      </div>
    </div>
  );
};

export default PreExistingMedicalCondition;
