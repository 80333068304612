const Misrepresentation = () => {
  return (
    <div>
      <p>
        You have a duty to take reasonable care not to make a misrepresentation ("Duty") to us. This
        applies whenever you enter into, vary, extend or reinstate your policy.
      </p>

      <p>
        All questions that we ask must be answered truthfully, accurately and to the best of your
        knowledge; both for yourself and for any other listed traveller. A misrepresentation is a
        false or partially false response or statement which does not reflect the truth.
      </p>

      <p>
        Your Duty is considered on the basis of the relevant target market, whether you are
        represented by a broker, and circumstances and characteristics that you have disclosed to
        us.
      </p>

      <p>
        If you do not take reasonable care when answering our questions and therefore breach your
        Duty, we may cancel your policy or reduce the amount that we will pay you if you make a
        claim. If the misrepresentation was deliberately fraudulent, we may refuse to pay your claim
        or treat your policy as if it never existed.
      </p>

      <p>By continuing, you agree to meet the obligations of your Duty.</p>
    </div>
  );
};

export default Misrepresentation;
