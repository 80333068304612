import { createContext, useEffect, useLayoutEffect, useState } from "react";
import appStyles from "./App.module.css";
import "./Calendar.css";
import "./DatePicker.css";
import TripQuote from "./components/TripQuote";
import appConstants from "./config/app-constants";
import { IDataRedirection } from "./config/interfaces";

export interface IAppContext {}

export const AppContext = createContext<IAppContext | null>(null);

function useBreakpointClass() {
  const [bpClass, setBpClass] = useState<string>("");
  useLayoutEffect(() => {
    function updateClass() {
      setBpClass(
        window.innerWidth >= 1400
          ? appStyles.bfpXXL
          : window.innerWidth >= 1200
          ? appStyles.bfpXL
          : window.innerWidth >= 992
          ? appStyles.bfpLG
          : window.innerWidth >= 768
          ? appStyles.bfpMD
          : window.innerWidth >= 576
          ? appStyles.bfpSM
          : appStyles.bfpXS
      );
    }
    window.addEventListener("resize", updateClass);
    updateClass();
    return () => window.removeEventListener("resize", updateClass);
  }, []);
  return bpClass;
}

function App({ redirection }: IDataRedirection) {
  const bpClass = useBreakpointClass();
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${appConstants.GOOGLE_API_KEY}&libraries=places`;
    document.body.insertAdjacentElement("afterbegin", script);
  }, []);

  return (
    <div className={`${appStyles.battlefacePlugin} ${bpClass}`}>
      <TripQuote redirection={redirection} />
    </div>
  );
}

export default App;
