import { Formik, getIn } from "formik";
import { FC, useContext, useMemo, useState } from "react";
import Select from "react-select";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import QuoteApi from "../../api/quote";
import appConstants, {
  calculateAge,
  convertDateToApiFormat,
  getApplicantErrorClass,
  purchasePathDocs,
  selectControlStyling,
  selectOptionControlStyling,
  shortDateFormat,
} from "../../config/app-constants";
import {
  IOrderForm,
  ISingleTripQuoteContext,
  ISingleTripQuoteData,
  SelectOption,
} from "../../config/interfaces";
import Misrepresentation from "../Popups/Misrepresentation";
import PreExistingMedicalCondition from "../Popups/PreExistingMedicalCondition";
import Stepper from "../Stepper";
import { SingleTripQuoteContext } from "../TripQuote";
import NextButton from "../buttons/NextButton";
import PrevButton from "../buttons/PrevButton";
import AddressSearchInput from "../common/AddSearchInput";
import Footer from "../common/Footer";
import LoadingIndicator from "../common/LoadingIndicator";
import MobileFooter from "../common/MobileFooter";
import PaymentIcons from "../common/PaymentIcons";
import Popup from "../common/Popup";
import QuoteSummary from "../common/QuoteSummary";
import ScrollToFirstError from "../common/ScrollToFirstError";
import ShowApiError from "../common/ShowApiError";
import ShowFormikError from "../common/ShowFormikError";
import TripSummary from "../common/TripSummary";
interface OrderFormProps {}

const validationSchema = {
  applicants: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(),

      last_name: yup.string().required(),
      dob: yup.date().required(),
      is_main: yup.boolean(),
      email: yup.string().when("is_main", {
        is: true,
        then() {
          return yup
            .string()
            .email()
            .matches(appConstants.EMAIL_REGEX, "Invalid email address")
            .required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      contact_number: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      address: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      city: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      post_code: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      state_code: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),
    })
  ),
  isAustralianCitizen: yup
    .boolean()
    .required()
    .oneOf([true], "You must confirm the above statement."),
  PDSAndTMDConditions: yup.boolean().required().oneOf([true], "You must accept the PDS and TMD."),
  acceptedDutyTerms: yup.boolean().required().oneOf([true], "You must accept the terms."),
};

const OrderForm: FC<OrderFormProps> = () => {
  const { quoteData, quoteResult, setQuoteData, setOrder, prev, next } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const [showPreMedicalPopup, setShowPreMedicalPopup] = useState<boolean>(false);
  const [ShowMisrepresentationPopup, setShowMisrepresentationPopup] = useState<boolean>(false);

  const initApplicant = {
    first_name: "",
    last_name: "",
    dob: "",
    age_at_travel: "",
    email: "",
    contact_number: "",
    address: "",
    city: "",
    post_code: "",
    state_code: quoteData?.host_country?.states[0]?.code || "",
    is_main: false,
    trip_cost: 0,
  };

  const allApplicants = useMemo(() => {
    const _allApplicants: any[] = [];

    if (quoteData?.all_applicants?.length) {
      _allApplicants.push({
        ...quoteData.all_applicants[0],
        ...{
          country_code: quoteData?.host_country?.code,
          dob: quoteData.main_applicant,
          age_at_travel: calculateAge(quoteData.main_applicant).years,
          is_main: true,
        },
      });
    } else {
      _allApplicants.push({
        ...initApplicant,
        country_code: quoteData?.host_country?.code,
        dob: quoteData.main_applicant,
        age_at_travel: calculateAge(quoteData.main_applicant).years,
        is_main: true,
      });
    }

    quoteData.other_applicants
      ?.filter((obj: any) => {
        return obj !== null;
      })
      .forEach((dob: any, index: number) => {
        if (quoteData.all_applicants && quoteData.all_applicants[index + 1]) {
          _allApplicants.push({
            ...quoteData.all_applicants[index + 1],
            ...{
              country_code: quoteData.host_country?.code,
              dob: dob,
              age_at_travel: calculateAge(dob).years,
              is_main: false,
            },
          });
        } else {
          _allApplicants.push({
            ...initApplicant,
            country_code: quoteData.host_country?.code,
            dob: dob,
            age_at_travel: calculateAge(dob).years,
            is_main: false,
          });
        }
      });
    return _allApplicants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData.main_applicant, quoteData.other_applicants.length]);

  const _updatedValidationSchema = yup.object().shape(validationSchema);

  const initialFormValues: IOrderForm = {
    applicants: allApplicants,
    isAustralianCitizen: false,
    PDSAndTMDConditions: false,
    acceptedDutyTerms: false,
  };

  const handleFormSubmit = async (values: IOrderForm) => {
    try {
      setLoading(true);
      setHasError(null);

      const _quoteData: ISingleTripQuoteData = { ...quoteData };
      _quoteData.all_applicants = values.applicants;
      setQuoteData(_quoteData);

      const details: any[] = [];
      if (quoteResult?.deductibles?.excess_fee) {
        details.push({
          type: "deductible",
          detail: "excess_fee",
          amount: quoteResult?.deductibles?.excess_fee,
        });
      }

      quoteResult?.details.forEach((detail: any) => {
        switch (detail?.type) {
          case "level":
            if (detail.package_alias !== "rental_vehicle_damage_package") {
              details.push({
                product_package_id: detail.package_id,
                type: "level",
                value: `${detail.value}`,
              });
            }
            break;
          case "condition":
            details.push({
              product_package_id: detail.package_id,
              type: "level-condition",
              detail: detail.detail,
              value: `${detail.value}`,
            });
            break;
          default:
            details.push({
              product_package_id: detail.package_id,
              type: "package",
            });
            break;
        }
      });

      const destinations = quoteData.destinations?.map((dest: SelectOption) => {
        return { country_code: dest.code };
      });

      const data: any = {
        affiliate: {
          id: quoteData.affiliate_id,
        },
        applicants: values?.applicants?.map((obj: any) => {
          let object: any = {};
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === "string") {
              if (obj[key].trim() !== "") {
                object[key] = obj[key];
              }
            } else {
              object[key] = obj[key];
            }
          });
          const applicant = { ...object };
          applicant.dob = applicant.dob && convertDateToApiFormat(applicant?.dob);

          return applicant;
        }),
        currency_id: quoteData?.currency?.value,
        deposit_at: convertDateToApiFormat(new Date()),
        start_at: convertDateToApiFormat(quoteData.start_date),
        end_at: convertDateToApiFormat(quoteData.end_date),
        product_id: appConstants.productId,
        quote_id: quoteResult?.id,
        destinations,
        signed_at: convertDateToApiFormat(new Date()),
        sanction_search_id: 0,
        beneficiary: null,
        details: details,
      };

      const response: any = await QuoteApi.createOrder(data);

      if (response.error) {
        throw response;
      }

      setOrder(response.data);
      next();
    } catch (e: any) {
      console.log(e);
      setHasError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingIndicator />}
      <div className={appStyles.btnWrapper}>
        <PrevButton onClick={() => prev()} />
      </div>

      <Formik
        initialValues={initialFormValues}
        validationSchema={_updatedValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleSubmit,
          handleChange,
          isValid,
          submitCount,
          validateForm,
          setFieldTouched,
        }) => (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await validateForm();
              handleSubmit();
            }}
          >
            <ScrollToFirstError submitCount={submitCount} isValid={isValid} errors={errors} />
            <div className={`${appStyles.mainContainer}`}>
              <div className={`${appStyles.quoteResultWrapper}`}>
                <div className={`${appStyles.quoteDetailsBox}`}>
                  <Stepper />
                  <TripSummary />

                  {values.applicants.map((applicant: any, index: any) => {
                    return (
                      <div key={index}>
                        {!index ? (
                          <div className={appStyles.pageSubTitle}>
                            <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                              Primary Traveller Information (You)
                            </h5>
                          </div>
                        ) : (
                          <div className={appStyles.pageSubTitle}>
                            <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                              Other Traveller(s) Information
                            </h5>
                          </div>
                        )}
                        <div className={`${appStyles.cardWrap} ${appStyles.formBox}`}>
                          {!index ? (
                            <div className={appStyles.quoteFormBlockTitle}>
                              <h6 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                                Personal Information
                              </h6>
                            </div>
                          ) : null}
                          {index > 0 && (
                            <div className={appStyles.formRow}>
                              <div className={appStyles.quoteFormBlockTitle}>
                                <h6 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                                  Traveller {index + 1} (Date of birth:
                                  {shortDateFormat(applicant.dob)})
                                </h6>
                              </div>
                            </div>
                          )}
                          {!index ? (
                            <div className={appStyles.formRow}>
                              <label className={appStyles.labelStyle}>Your Full Name:</label>
                            </div>
                          ) : (
                            <div className={appStyles.formRow}>
                              <label className={appStyles.labelStyle}>Full Name:</label>
                            </div>
                          )}

                          <div className={appStyles.formRow}>
                            <div className={appStyles.formBlock}>
                              <div
                                className={`${appStyles.formField} ${getApplicantErrorClass(
                                  errors,
                                  touched,
                                  index,
                                  "first_name"
                                )}`}
                              >
                                <input
                                  type='text'
                                  name={`applicants.${index}.first_name`}
                                  id={`applicants.${index}.first_name`}
                                  value={values.applicants[index]?.first_name}
                                  className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                  placeholder='First Name'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ShowFormikError name={`applicants.${index}.first_name`} />
                              </div>
                            </div>

                            <div className={appStyles.formBlock}>
                              <div
                                className={`${appStyles.formField} ${getApplicantErrorClass(
                                  errors,
                                  touched,
                                  index,
                                  "last_name"
                                )}`}
                              >
                                <input
                                  type='text'
                                  name={`applicants.${index}.last_name`}
                                  id={`applicants.${index}.last_name`}
                                  value={values.applicants[index]?.last_name}
                                  className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                  placeholder='Last Name'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ShowFormikError name={`applicants.${index}.last_name`} />
                              </div>
                            </div>
                          </div>
                          {index === 0 && (
                            <>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField} ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "email"
                                    )}`}
                                  >
                                    <label className={appStyles.labelStyle}>Your Email:</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.email`}
                                      id={`applicants.${index}.email`}
                                      value={values.applicants[index]?.email}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Email Address'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ShowFormikError name={`applicants.${index}.email`} />
                                  </div>
                                </div>

                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField} ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "contact_number"
                                    )}`}
                                  >
                                    <label className={appStyles.labelStyle}>Your Phone:</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.contact_number`}
                                      id={`applicants.${index}.contact_number`}
                                      value={values.applicants[index]?.contact_number}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Contact Number'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ShowFormikError name={`applicants.${index}.contact_number`} />
                                  </div>
                                </div>
                              </div>

                              <div className={appStyles.quoteFormBlockTitle}>
                                <h6 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                                  Address
                                </h6>
                              </div>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField} ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "address"
                                    )}`}
                                  >
                                    <label className={appStyles.labelStyle}>Street Address:</label>

                                    <AddressSearchInput
                                      name={`applicants.${index}.address`}
                                      id={`applicants.${index}.address`}
                                      value={values.applicants[index]?.address}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple} ${appStyles.bfAddressSearchField}`}
                                      isDisabled={true}
                                      onPlaceSelect={(_address: any) => {
                                        if (_address) {
                                          const { full_address, city, pin_code, state } = _address;
                                          setFieldValue(
                                            `applicants.${index}.address`,
                                            full_address || ""
                                          );

                                          setFieldValue(`applicants.${index}.city`, city || "");

                                          setFieldValue(
                                            `applicants.${index}.post_code`,
                                            pin_code || ""
                                          );
                                          setFieldValue(
                                            `applicants.${index}.state_code`,
                                            state || ""
                                          );

                                          setFieldTouched(`applicants.${index}.address`, false);
                                          setFieldTouched(`applicants.${index}.post_code`, false);
                                          setFieldTouched(`applicants.${index}.city`, false);
                                        } else {
                                          setFieldValue(`applicants.${index}.address`, "");
                                          setFieldValue(`applicants.${index}.city`, "");
                                          setFieldValue(`applicants.${index}.post_code`, "");
                                        }
                                      }}
                                      onPlaceClear={() => {
                                        setFieldValue(`applicants.${index}.address`, "");
                                        setFieldValue(`applicants.${index}.city`, "");
                                        setFieldValue(`applicants.${index}.post_code`, "");
                                      }}
                                    />
                                    <ShowFormikError name={`applicants.${index}.address`} />
                                  </div>
                                </div>
                              </div>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField} ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "city"
                                    )}`}
                                  >
                                    <label className={appStyles.labelStyle}>City:</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.city`}
                                      id={`applicants.${index}.city`}
                                      value={values.applicants[index]?.city}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='City'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ShowFormikError name={`applicants.${index}.city`} />
                                  </div>
                                </div>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField} ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "post_code"
                                    )}`}
                                  >
                                    <label className={appStyles.labelStyle}>Postcode:</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.post_code`}
                                      id={`applicants.${index}.post_code`}
                                      value={values.applicants[index]?.post_code}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Postcode'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ShowFormikError name={`applicants.${index}.post_code`} />
                                  </div>
                                </div>

                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField}  ${
                                      getIn(touched, `applicants.${index}.state_code`) &&
                                      getIn(errors, `applicants.${index}.state_code`)
                                        ? appStyles.hasError
                                        : ""
                                    }`}
                                  >
                                    <label className={appStyles.labelStyle}>State:</label>
                                    <div className={appStyles.formRow}>
                                      <div className={`${appStyles.formField}`}>
                                        <Select
                                          isSearchable={true}
                                          name={`applicants.${index}.state_code`}
                                          id={`applicants.${index}.state_code`}
                                          placeholder='State'
                                          options={quoteData?.host_country.states}
                                          classNames={{
                                            control: selectControlStyling,
                                            option: selectOptionControlStyling,
                                          }}
                                          className={`${appStyles.fontWeightSemiBold}`}
                                          onBlur={handleBlur}
                                          onChange={(newValue) => {
                                            setFieldValue(
                                              `applicants.${index}.state_code`,
                                              newValue.code
                                            );
                                          }}
                                          value={quoteData?.host_country.states.find(
                                            (_state: any) =>
                                              _state.code === values.applicants[index].state_code
                                          )}
                                          getOptionValue={(option: any) => `${option["code"]}`}
                                          getOptionLabel={(option: any) => `${option["name"]}`}
                                          blurInputOnSelect={false}
                                        />
                                        <ShowFormikError name={`applicants.${index}.state_code`} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div className={appStyles.pageSubTitle}>
                    <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                      Terms & Conditions
                    </h5>
                  </div>

                  <div className={`${appStyles.cardWrap}`}>
                    <div className={appStyles.formRow}>
                      <p className={appStyles.helperText}>
                        <b>Please make sure you’ve read the terms of your cover.</b>
                      </p>
                    </div>

                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.isAustralianCitizen && touched.isAustralianCitizen
                              ? appStyles.hasError
                              : ""
                          }`}
                        >
                          <div className={`${appStyles.bfCheckBox} ${appStyles.pT0}`}>
                            <label
                              htmlFor='isAustralianCitizen'
                              className={`${appStyles.helperText} ${appStyles.mT0}`}
                            >
                              <input
                                type='checkbox'
                                id='isAustralianCitizen'
                                name='isAustralianCitizen'
                                value='yes'
                                checked={values.isAustralianCitizen}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("isAustralianCitizen", e.target.checked);
                                }}
                              />
                              <span className={`${appStyles.bfCheckmark}`}></span>By checking here,
                              I confirm that all travellers are Australian residents, and understand
                              there is no cover for pre-existing medical conditions except for those
                              listed under&nbsp;
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setShowPreMedicalPopup(true);
                                }}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.bfBlackLink}`}
                              >
                                automatically covered pre-existing medical conditions&nbsp;
                              </a>
                              and when all eligibility criteria have been met.
                            </label>
                            <ShowFormikError
                              name='isAustralianCitizen'
                              msg={errors.isAustralianCitizen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.PDSAndTMDConditions && touched.PDSAndTMDConditions
                              ? appStyles.hasError
                              : ""
                          }`}
                        >
                          <div className={`${appStyles.bfCheckBox} ${appStyles.pT0}`}>
                            <label
                              htmlFor='PDSAndTMDConditions'
                              className={`${appStyles.helperText} ${appStyles.mT0}`}
                            >
                              <input
                                type='checkbox'
                                id='PDSAndTMDConditions'
                                name='PDSAndTMDConditions'
                                value='yes'
                                checked={values.PDSAndTMDConditions}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("PDSAndTMDConditions", e.target.checked);
                                }}
                              />
                              <span className={appStyles.bfCheckmark}></span>By checking here, I
                              confirm that I have read and understood the&nbsp;
                              <a
                                href={`${purchasePathDocs.PDS}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.bfBlackLink}`}
                              >
                                PDS
                              </a>
                              &nbsp;and&nbsp;
                              <a
                                href={`${purchasePathDocs.TMD}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.bfBlackLink}`}
                              >
                                TMD
                              </a>
                              , including any&nbsp;
                              <a
                                href={`${purchasePathDocs.travelAlerts}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.bfBlackLink}`}
                              >
                                travel alerts
                              </a>
                              &nbsp;relevant to my trip.
                            </label>
                            <ShowFormikError
                              name='PDSAndTMDConditions'
                              msg={errors.PDSAndTMDConditions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.acceptedDutyTerms && touched.acceptedDutyTerms
                              ? appStyles.hasError
                              : ""
                          }`}
                        >
                          <div className={`${appStyles.bfCheckBox} ${appStyles.pT0}`}>
                            <label
                              htmlFor='acceptedDutyTerms'
                              className={`${appStyles.helperText} ${appStyles.mT0}`}
                            >
                              <input
                                type='checkbox'
                                id='acceptedDutyTerms'
                                name='acceptedDutyTerms'
                                value='yes'
                                checked={values.acceptedDutyTerms}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("acceptedDutyTerms", e.target.checked);
                                }}
                              />
                              <span className={`${appStyles.bfCheckmark}`}></span>By checking here,
                              I confirm that I have read and accepted&nbsp;
                              <span
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setShowMisrepresentationPopup(true);
                                }}
                                className={`${appStyles.bfBlackLink}`}
                              >
                                my Duty to take reasonable care not to make a misrepresentation
                              </span>
                              .
                            </label>
                            <ShowFormikError
                              name='acceptedDutyTerms'
                              msg={errors.acceptedDutyTerms}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev}`}>
                      <div className={appStyles.buttonBlockPrev}>
                        <PrevButton
                          onClick={() => {
                            const _quoteData: ISingleTripQuoteData = {
                              ...quoteData,
                            };
                            _quoteData.all_applicants = values.applicants;
                            setQuoteData(_quoteData);
                            prev();
                          }}
                        />
                      </div>
                      <div className={`${appStyles.buttonBlockNext}`}>
                        <NextButton title='Buy Now' type='submit' />
                        <PaymentIcons />
                      </div>
                    </div>
                  </div>

                  {hasError && <ShowApiError />}

                  <Footer />

                  <MobileFooter title='Buy Now' />
                </div>
                <QuoteSummary pay={true} />
              </div>
            </div>
            {showPreMedicalPopup && (
              <Popup
                onClose={() => {
                  setShowPreMedicalPopup(false);
                }}
                title='Automatically covered pre-existing medical conditions'
                children={<PreExistingMedicalCondition />}
                footer={
                  <div
                    className={`${appStyles.buttonBlock} ${appStyles.buttonBlockAlignEnd} ${appStyles.mT10}`}
                  >
                    <button
                      onClick={() => {
                        setFieldValue("isAustralianCitizen", true);
                      }}
                      type='button'
                      className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfButtonSecondary} `}
                    >
                      Accept
                    </button>
                  </div>
                }
              />
            )}
            {ShowMisrepresentationPopup && (
              <Popup
                onClose={() => {
                  setShowMisrepresentationPopup(false);
                }}
                title='Duty to take reasonable care not to make a misrepresentation'
                children={<Misrepresentation />}
                footer={
                  <div
                    className={`${appStyles.buttonBlock} ${appStyles.buttonBlockAlignEnd} ${appStyles.mT10}`}
                  >
                    <button
                      onClick={() => {
                        setFieldValue("acceptedDutyTerms", true);
                      }}
                      type='button'
                      className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfButtonSecondary} `}
                    >
                      Accept
                    </button>
                  </div>
                }
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default OrderForm;
